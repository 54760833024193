<template>
  <div class="container">
    <page-title :title="`Company Information : ${company.name}`">
      <template slot="button" v-if="$can('companies.destroy')">
        <a href="#" @click.prevent="destroy" class="btn btn-danger float-right"><i class="lni-trash mr-2"></i> Delete</a>
      </template>
      <template slot="button" v-if="$can('companies.update')">
        <router-link :to="{ name: 'companies.edit' }" class="btn btn-warning mr-2 float-right"><i class="lni-pencil mr-2"></i> Edit</router-link>
      </template>
    </page-title>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-md-6">
                  <p>
                    Name:
                    <span>{{ company.name }}</span>
                  </p>
                  <p>
                    Registration Number:
                    <span>{{ company.crp }}</span>
                  </p>
                  <p>
                    Phone Number:
                    <span>{{ company.phone_number }}</span>
                  </p>
                </div>
                <div class="col-md-6">
                  <p>
                    Physical Address:
                    <span>{{ company.physical_address }}</span>
                  </p>
                  <p>
                    Postal Address:
                    <span>{{ company.postal_address }}</span>
                  </p>
                  <p>
                    Email Address:
                    <span>{{ company.email }}</span>
                  </p>
                  <p>
                    Approval Status:
                    <span>
                      <span
                        :class="
                          ` capitalize badge badge-${
                            company.status == 'pending'
                              ? 'warning'
                              : company.status == 'rejected'
                              ? 'danger'
                              : 'success'
                          }`
                        "
                        >{{ company.status }}</span
                      >
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-md-12">
                <ul class="nav nav-tabs" id="company-info-tab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="pills-directors-tab"
                      data-toggle="tab"
                      href="#pills-directors"
                      role="tab"
                      aria-controls="pills-directors"
                      aria-selected="true"
                      >Directors</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="pills-documents-tab"
                      data-toggle="tab"
                      href="#pills-documents"
                      role="tab"
                      aria-controls="pills-documents"
                      aria-selected="false"
                      >Documents</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="company-details-info-content">
                  <div
                    class="tab-pane fade show active"
                    id="pills-directors"
                    role="tabpanel"
                    aria-labelledby="pills-directors-tab"
                  >
                    <a href="#" v-if="$can('companies.update')" @click.prevent="showAddDirectorsModal = true" class="btn btn-secondary btn-sm float-right"><i class="lni-plus mr-2"></i>Add Directors</a>
                    <h4>Company Directors</h4>
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Phone Number</th>
                          <th>Email Address</th>
                          <th>ID Number</th>
                          <th v-if="$can('companies.update')"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(director, i) in company.directors"
                          :key="`director-${i}`"
                        >
                          <td>{{ i + 1 }}</td>
                          <td>{{ director.name }}</td>
                          <td>{{ director.phone_number }}</td>
                          <td>{{ director.email }}</td>
                          <td>{{ director.id_number }}</td>
                          <td v-if="$can('companies.update')"><a href="#" class="btn btn-sm btn-danger" @click.prevent="deleteDirector(director)"><i class="lni-trash"></i></a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-documents"
                    role="tabpanel"
                    aria-labelledby="pills-documents-tab"
                  >
                    <a
                      v-if="$can('companies.update')"
                      href="#"
                      @click.prevent="showUploadModal = true"
                      class="btn btn-secondary btn-sm float-right mt-2"
                      >Upload Documents</a
                    >
                    <h4>Company Documents</h4>
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Document Name</th>
                          <th>Download</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(attachment, i) in company.attachments"
                          :key="`document-${i}`"
                        >
                          <td>{{ i + 1 }}</td>
                          <td>{{ attachment.name }}</td>
                          <td>
                            <a
                              :href="attachment.path"
                              target="_blank"
                              class="btn btn-info btn-sm px-4"
                            >
                              <i class="lni-download"></i>
                            </a>
                          </td>
                          <td>
                            <a v-if="$can('companies.update')" href="#" class="btn btn-danger btn-sm" @click.prevent="deleteFile(attachment)"><i class="lni-trash"></i></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <modal
              :showModal="showUploadModal"
              @close="showUploadModal = false"
            >
              <template v-slot:header>
                <h4>Upload Documents</h4>
              </template>
              <form @submit.prevent="uploadDocuments">
                <div class="form-group">
                  <label>Select File</label>
                  <input
                    type="file"
                    class="form-control"
                    ref="the_document"
                    required
                  />
                </div>
                <div class="form-group">
                  <button class="btn btn-secondary">Upload</button>
                </div>
              </form>
            </modal>

            <modal
              :showModal="showAddDirectorsModal"
              @close="showAddDirectorsModal = false"
            >
              <template v-slot:header>
                <h4>Add Directors</h4>
              </template>
              <form @submit.prevent="addDirector">
                <div class="form-group">
                  <label>Full Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="new_director.name"
                    placeholder="Name"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="new_director.email"
                    placeholder="Email"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="new_director.phone_number"
                    placeholder="Phone Number"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>ID Number</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="new_director.id_number"
                    placeholder="ID Number"
                    required
                  />
                </div>
                <div class="form-group">
                  <button class="btn btn-secondary">Save</button>
                </div>
              </form>
            </modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      company: {},
      showUploadModal: false,
      showAddDirectorsModal: false,
      new_director: {},
    };
  },

  methods: {
    deleteDirector(director) {
      this.$loader.start()
      this.$axios.delete(`/api/v1/dashboard/company-directors/${director.id}`).then(() => {
        this.showAddDirectorsModal = false
        this.fetch()
        this.$loader.stop()
      })
    },

    fetch() {
      this.$loader.start()

      this.$axios
        .get("/api/v1/dashboard/companies/" + this.$route.params.id)
        .then((response) => {
          this.company = response.data;
          this.$loader.stop()
        })
        .catch((error) => {
          this.$http(error.response);
          this.$loader.stop()
        });
    },

    destroy() {
      this.$axios.delete(`/api/v1/dashboard/companies/${this.company.id}`).then(() => {
        this.$router.push({ name: 'companies.index' })
      }).catch(error => {
        this.$http.serverError(error.response)
      })
    },

    uploadDocuments() {
      let the_document = this.$refs.the_document.files[0];

      var myData = new FormData();
      myData.append("attachment", the_document);
      myData.append("company_id", this.company.id);

      this.$loader.start()
      this.$axios
        .post("/api/v1/dashboard/company-attachments", myData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then(() => {
          this.showUploadModal = false;
          this.fetch();
          this.$loader.stop()
        })
        .catch((error) => {
          this.$http(error.response);
          this.$loader.stop()
        });
    },

    addDirector() {
      let data = this.new_director
      data.company_id = this.company.id
      this.$loader.start()

      this.$axios.post('/api/v1/dashboard/company-directors', data).then(() => {
        this.showAddDirectorsModal = false
        this.$loader.stop()
        this.fetch()
      })
    },

    deleteFile(attachment) {
      this.$loader.start()
      this.$axios.delete(`/api/v1/dashboard/company-attachments/${attachment.id}`).then(() => {
        this.$loader.stop()
        this.fetch()
      })
    }
  },
};
</script>
